import * as React from "react"
// import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


import ContentWrapper from "../../components/wrappers/contentWrapper"
import Heading from "../../components/typography/heading";
import SubHeading from "../../components/typography/subHeading";
import SpanBold from "../../components/typography/SpanBold";
import ColoredWrapper from "../../components/wrappers/colorWrapper";


const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const AccountsReceivablePage = () => {
  return (
    <Layout>
      <Seo
        title="Payroll Services"
        description="Proper payroll is vital to your employee satisfaction. Let’s keep your employees excited for payday by making sure they are always paid correctly, and on time."
        canonical="https://labellebookkeeping.com/services/payroll-services/"
      />
      <HeroImage>
        <StaticImage
          src="../../images/hero/hero_accounts_payable_services.jpg"
          alt="accounts payable services"
          objectPosition="50% 50%"
        />
      </HeroImage>

      <ContentWrapper>
        <Heading>
          Payroll Services
        </Heading>
        <p>
          Proper payroll is vital to your employee satisfaction. Let’s keep your employees excited for payday by making sure they are always paid correctly, and on time.
        </p>
        <p>
          <SpanBold>Services include:</SpanBold>
        </p>
        <p>
          <ul>
            <li>Bi-Weekly or Semi-Monthly payroll processing</li>
            <li>Support with CRA inquiries and/or payroll related audits</li>
            <li>Assist with employee terminations and draft records of employments</li>
            <li>Employee reimbursements</li>
            <li>Annual T4 Processing</li>
          </ul>
        </p>
      </ContentWrapper>
      <ColoredWrapper>
        <ContentWrapper>
          <SubHeading>
            Hassle-Free and Accurate Payroll Processing
          </SubHeading>
          <p>
            Payroll is time consuming. Save the time dealing with timesheet, reviewing pay stubs, and ensuring each pay is properly administered by allowing me to handle the workload for you.</p>
          <p>
            With bi-weekly or semi-monthly pay runs, all you’ll need to do is review and approve each pay period.
          </p>
          <SubHeading>
            New Hires and Terminations
          </SubHeading>
          <p>
            I will handle the entire process of payroll from start to finish for you. From onboarding to departure, or termination, I’ll maintain the employee set-up, processing vacation payouts, and creating and submitting records of employment.
          </p>
          <SubHeading>
            Preparing for Year-End
          </SubHeading>
          <p>
            In order to assist your employees with preparing their tax returns, I will prepare the T4s necessary and have everything drafted for them to have a smooth tax process.
          </p>
        </ContentWrapper>
      </ColoredWrapper>
    </Layout>
  )
}

export default AccountsReceivablePage
